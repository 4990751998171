import './App.scss'
import React, {lazy, Suspense, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./Component/Loader/Loader";
import Navbarec from "./Navbar/Navbar";
import Button from "react-bootstrap/Button";

const Home = lazy(() => import("./Home/Home"));
// const Navbarec = lazy(() => import("./Navbar/Navbar"));
const Aboutus = lazy(() => import("./Aboutus/Aboutus"));
const Footer = lazy(() => import("./Footer/Footer"));
const News = lazy(() => import("./News/News"));
const Contact = lazy(() => import("./Contact/Contact"));
const Experience = lazy(() => import("./Experience/Experience"));
const Legal = lazy(() => import("./Legal/Legal"));

const pages = [
    {page: Home, path: "/"},
    {page: Aboutus, path: "/aboutus"},
    {page: Experience, path: "/experience"},
    {page: News, path: "/news"},
    {page: Contact, path: "/contact"},
    {page: Legal, path: "/legal"},
    ];

let route = () => {
    const item = []
    for (let i = 0; i < pages.length ; i++){
        item.push(<Route key={i} exact={true} path={pages[i].path} component={pages[i].page}
        />)
    }
    return item
}

function Page() {
    const body = document.querySelector('#root');
    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Route key={-1} exact={false} path={"/"} component={Navbarec}/>
            </Suspense>
            <Suspense fallback={<Loader />}>
                <div style={{minHeight: "calc(100vh - 8.7em)", backgroundSize: "100vw"}}>
                    <Switch>
                            {route()}
                    </Switch>
                </div>
                <Footer/>
                <Button aria-label="Scroll to top" id="back-to-top" className="btn btn-light btn-lg back-to-top border rounded-lg shadow position-fixed" style={{zIndex: "1000"}}
                   role="button" onClick={() => {
                    body.scrollIntoView({behavior: 'smooth'})
                }}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-up" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                    </svg>
                </Button>
            </Suspense>
        </Router>
    )
}

export default function App() {
    const key = "6LfaKCQaAAAAACSRYp66lszPyJ5HUibyzYIgGfas";

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${key}`, function () {
        });
    }, []);
    
    return (
        <Page />
    );
}
