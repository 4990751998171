import React from "react";
import {Spinner} from "react-bootstrap";

const Loader = () => (
    <div className="loading-pos text-center">
        <img src={require("../../Image/Logo_ec_final.png")} className="App-logo" alt="logo" />
        <div>
            <Spinner animation="border" variant="ec2"/>
        </div>
    </div>
);

export default Loader;
